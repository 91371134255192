import CONFIG from 'config';

/**
 * Класс работы с окружением
 */
class Env {
  /**
   * Получить переменную окружения
   */
  static getEnv(): string {
    const NODE_ENV = process.env.NODE_ENV || '';
    return NODE_ENV.trim();
  }

  /**
   * Приложение запущено режиме разработки
   */
  static isDevelopment(): boolean {
    return Env.getEnv() === 'development';
  }

  /**
   * Приложение запущено в прод режиме
   */
  static isProduction(): boolean {
    return Env.getEnv() === 'production';
  }

  /**
   * Пока добавим сюда, когда появится больше различий для дев и прод режимов,
   * можно разделить на несколько конфигов config.dev.ts, config.prod.ts,
   * а для работы с ними создать отдельный класс, вроде ConfigManager
   */
  static getPublicPath(): string {
    return Env.isProduction() ? CONFIG.PUBLIC_PATH : '';
  }

  static getApiUrl(): string {
    const { location } = window;
    return process.env.REACT_APP_API_URL || (location.protocol + '//' + location.host + '/');
  }

  static getWsUrl(): string {
    const { location } = window;
    return process.env.REACT_APP_WS_URL || `wss://${location.host}/ws`;
  }

  static getIconHost(): string {
    return process.env.REACT_APP_ICON_HOST || ``;
  }

  static getReleaseHash(): string {
    return process.env.REACT_APP_SENTRY_RELEASE || '';
  }

  static getDwhNMocked(): string {
    return process.env.REACT_APP_DWH_MOCKED || '';
  }

  static getMetricUrl(): string {
    return process.env.REACT_APP_METRIC_URL || Env.getPublicPath();
  }

  // NodeJS ver named as currentBuilder for security reasons, itsec ask to name like this
  static getCurrentBuilder(): string {
    return `${process.env.REACT_APP_BUILD_NODE_VERSION || ''} ${
      process.env.REACT_APP_BUILD_NPM_VERSION || ''
    }`;
  }

  static getAppVersion(): string {
    return (
      (process.env.REACT_APP_BUILD_TAG || '') +
      ' ' +
      (process.env.REACT_APP_BUILD_COMMIT || '')
    );
  }
}

export default Env;
