import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAdditionalFeaturesList } from 'api/overview';
import {
  resetFeatures,
  setAdditionalFeatures,
} from 'actions/additionalFeature';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import checkAccess from 'decorators/checkAccess';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';

import PageTemplate from 'components/pageTemplate';
import QuickActions from './components/quickActions';
import OverviewDocs from './components/overviewDocs';
import Link from 'components/ui/link';
import Loader from 'components/ui/loader';
import path from 'helpers/path';
import Messages from 'constants/rpcTypes';
import { RootState, StoreProps } from 'store';
import { AdditionalFeatures } from 'reducers/additionalFeatures';
import Icon from 'components/ui/icon';
import './overview.scss';

interface OwnProps {
  wl: string;
  language: string;
  additionalFeatures: AdditionalFeatures;
}

interface State {
  isLoadingFeatures: boolean;
}

type Props = OwnProps & IntlProps & StoreProps & WithPermissions;

@checkAccess([Messages.GetStarted_View])
class OverviewContainer extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingFeatures: false,
    };
  }
  componentDidMount() {
    return;
    if (!this.props.additionalFeatures.isFetched) {
      this.fetchFeatures();
    } else if (
      this.props.language !==
      this.props.additionalFeatures.list[0]?.languageCode
    ) {
      this.props.dispatch(resetFeatures());
      this.fetchFeatures();
    }
  }

  render() {
    const { wl, getTranslate, isEnabled } = this.props;

    return (
      <div className='page-overview'>
        <PageTemplate.Main topPadding>
          <PageTemplate.Container>
            <div className='page-overview__layout'>
              <div className='page-overview__main'>
                <div className='page-overview__block page-overview__block_actions'>
                  <div className='page-overview__block-title'>
                    {getTranslate('getStarted.quickActions.header')}
                  </div>
                  <div className='page-overview__block-text'>
                    {getTranslate('getStarted.quickActions.info.text')}
                  </div>
                  <div className='page-overview__block-content'>
                    <QuickActions />
                  </div>
                </div>

                {isEnabled(Messages.Article_Menu) && (
                  <>
                    <div className='page-overview__block page-overview__block_news'>
                      news
                    </div>
                    <div className='page-overview__block page-overview__block_help'>
                      <div className='page-overview__help'>
                        <Icon
                          name='im-Info'
                          size={30}
                          className='page-overview__help-icon'
                        />
                        <div className='page-overview__help-inner'>
                          <div className='page-overview__help-col'>
                            <div className='page-overview__help-title'>
                              {getTranslate('getStarted.help.title')}
                            </div>
                            <div className='page-overview__help-text'>
                              {getTranslate('getStarted.help.text')}
                            </div>
                          </div>
                          <a
                            href={`https://dashboard.${wl}.com/help/general`}
                            target='blank'
                            className='page-overview__help-col ui-button ui-button_status-outline ui-button_size-normal'>
                            {getTranslate('getStarted.help.link')}
                          </a>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </PageTemplate.Container>
        </PageTemplate.Main>
      </div>
    );
  }

  renderFeature = () => {
    if (
      !this.state.isLoadingFeatures &&
      !this.props.additionalFeatures.isFetched
    ) {
      return <div>Features loading error :_( Please, try again later</div>;
    }
    return (
      <>
        <div className='page-overview__feature-list'>
          {this.props.additionalFeatures.list.slice(0, 5).map((feature) => {
            return (
              <div
                className='page-overview__feature'
                key={feature.additionalFeatureId}>
                <img
                  src={feature.iconPath}
                  alt='decor'
                  className='page-overview__feature-icon'
                />
                <span className='page-overview__feature-title'>
                  {feature.title}
                </span>
                <Link
                  className='page-overview__feature-status'
                  url={path(
                    `/get-started/features#${feature.additionalFeatureId}`
                  )}>
                  {this.props.getTranslate(
                    'getStarted.additionalFeatures.learnMore.button'
                  )}
                </Link>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  fetchPage = async () => {
    // const data = await getOverviewPage();
  };

  fetchFeatures = async () => {
    this.setState({ isLoadingFeatures: true });
    try {
      const data = await getAdditionalFeaturesList({
        language: this.props.language,
      });
      this.props.dispatch(setAdditionalFeatures(data));
    } catch (e) {
      console.error('Features loading error: ', e);
    } finally {
      this.setState({ isLoadingFeatures: false });
    }
  };
}

const mapStateToProps = (state: RootState) => ({
  wl: state.settings.wl,
  language: state.user.interfaceLang,
  additionalFeatures: state.additionalFeatures,
});

export default connect(mapStateToProps)(
  addPermissions(addTranslation(OverviewContainer))
);
