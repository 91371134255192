import React, { Component } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Button from 'components/ui/button';
import Form from 'components/ui/form';
import filtersKeys from 'constants/filters';
import SearchFiltersType from 'types/SearchFilters';
import { AnyObject } from 'types/Common';
import Input from 'components/ui/input';
import Panel from 'components/ui/panel';
import ConditionalWrapper from 'components/ui/conditionalWrapper';
import './searchFilters.scss';

interface Props extends IntlProps {
  filters: SearchFiltersType;
  validationErrors: AnyObject;
  canApply: boolean;
  isOpened: boolean;
  isMobile: boolean;
  onChange: (filterId: any, value: any) => void;
  onApply: () => void;
  onReset: () => void;
  onClose: () => void;
}

class SearchFilters extends Component<Props> {
  render() {
    const { canApply, onApply, onClose, isOpened, isMobile, getTranslate } =
      this.props;

    return (
      <div id='search-container' className='search-filters'>
        <ConditionalWrapper
          condition={isMobile}
          wrap={(children) => (
            <Panel
              id='search-panel'
              isOpened={isOpened}
              customClass='ui-panel_search ui-panel_fixed'
              excludeFromCloseTrigger={['#search-button']}
              onClose={onClose}
              modern>
              <div className='search-filters__inner'>
                <div className='search-filters__header'>
                  <div className='search-filters__title'>
                    {getTranslate('searchFilters.title')}
                  </div>
                  {this.renderResetButton()}
                </div>
                {children}
              </div>
            </Panel>
          )}>
          <Form onSubmit={onApply} customClass='search-filters__form search-filters__form_f1'>
            <div className='search-filters__list'>
              <div className='search-filters__column search-filters__column_period'>
                {getTranslate('searchFilters.period.text')}
              </div>
              <div className='search-filters__column search-filters__column_fullw'>
                <div className='search-filters__column'>
                  {this.renderInput(filtersKeys.paymentId)}
                  {this.renderInput(filtersKeys.operationId)}
                </div>
                <div className='search-filters__column'>
                  {this.renderInput(filtersKeys.projectId)}
                  {this.renderInput(filtersKeys.customerId)}
                </div>
                <div className='search-filters__column'>
                  {this.renderInput(filtersKeys.accountNumber)}
                  {this.renderInput(filtersKeys.customerPhone)}
                </div>
                <div className='search-filters__column search-filters__column_mobile'>
                  {this.renderInput(filtersKeys.rrn)}
                  {this.renderInput(filtersKeys.customerEmail)}
                </div>
                <div className='search-filters__column'>
                  {this.renderInput(filtersKeys.arn)}
                </div>
              </div>
            </div>
            <div className='search-filters__buttons'>
              <Button
                text={getTranslate('searchFilters.buttons.close')}
                status='outline'
                size='small'
                id='search-button-reset'
                onClick={onClose}
                customClass='search-filters__button-desktop'
              />
              {this.renderResetButton(true)}
              <Button
                text={getTranslate('searchFilters.buttons.confirm')}
                size={isMobile ? 'normal' : 'small'}
                status='primary'
                type='submit'
                id='search-button-submit'
                disabled={!canApply}
                customClass='search-filters__button-apply'
              />
            </div>
          </Form>
        </ConditionalWrapper>
      </div>
    );
  }

  renderInput(filterId, isDisabled = false) {
    const { filters, validationErrors, onChange, isMobile, getTranslate } =
      this.props;

    return (
      <Input
        id={filterId}
        placeholder={
          isMobile
            ? getTranslate(`search.${filterId}.placeholder`)
            : getTranslate(`search.${filterId}.label`)
        }
        disabled={isDisabled}
        value={filters[filterId]}
        onChange={(event) => onChange(filterId, event.target.value)}
        size='small'
        error={validationErrors[filterId]}
        customClass='search-filters__field'
        modern={isMobile}
        label={isMobile ? getTranslate(`search.${filterId}.label`) : ''}
      />
    );
  }

  renderResetButton = (isDesktop = false) => {
    const { isMobile, onReset, getTranslate } = this.props;
    return (
      <Button
        text={getTranslate('searchFilters.buttons.reset')}
        status='outline'
        size={isMobile ? 'normal' : 'small'}
        id='search-button-reset'
        onClick={onReset}
        customClass={isDesktop ? 'search-filters__button-desktop' : ''}
      />
    );
  };
}

export default addTranslation(SearchFilters);
