import filtersKeys from 'constants/filters';
import SearchFilters from 'types/SearchFilters';
import DateHelpers from "../helpers/Date";

class FiltersStateFactory {
  static getInitialSearchFilters(): SearchFilters {
    const creationDateFilter = {
      type: 'custom',
      values: DateHelpers.getFormattedMonthsFromNow(),
    };

    return {
      [filtersKeys.paymentId]: '',
      [filtersKeys.projectId]: '',
      [filtersKeys.operationId]: '',
      [filtersKeys.customerId]: '',
      [filtersKeys.customerEmail]: '',
      [filtersKeys.accountNumber]: '',
      [filtersKeys.customerPhone]: '',
      [filtersKeys.cardHolderName]: '',
      [filtersKeys.arn]: '',
      [filtersKeys.rrn]: '',
      [filtersKeys.date]: creationDateFilter,
    };
  }
}

export default FiltersStateFactory;
