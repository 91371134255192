export default {
  date: 'DD.MM.YYYY',
  time: 'HH:mm:ss',
  datetimeShort: 'DD.MM.YYYY HH:mm',
  datetime: 'DD.MM.YYYY HH:mm:ss',
  dayOfWeek: 'dddd',
  crmDate: 'MM/DD/YYYY HH:mm:ss',
  crmDateShort: 'MM/DD/YYYY',
  noTZCommon: 'YYYY-MM-DD HH:mm:ss',
  supportChatMsg: 'DD.MMM.YYYY HH:mm',
  timeMS: 'HH:mm:ss:SSS',
} as const;

export enum periodTypes {
  Today = 'today',
  TodayFullDay = 'todayFullDay',
  Yesterday = 'yesterday',
  LastWeek = 'week',
  LastMonth = 'month',
  LastMonthFn = 'month_fn',
  LastQuarter = '3months',
  LastQuarterFn = '3months_fn',
  LastYear = 'year',
  LastYearFromNow = 'year_fn',
}

export enum groupPeriodBy {
  Day = '1d',
  Week = '1w',
  Month = '1m',
  Quarter = '3m',
}
